






























import { Component, Vue } from 'vue-property-decorator';

import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import FlagshipProfilingCardViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/flagship-profiling-card-view-model';

@Component({
  name: 'FlagshipProfilingCard',
  components: {
    LoadingDataCard,
    FlagshipProfilingModalSteps: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingModalSteps.vue'),
  },
})
export default class FlagshipProfilingCard extends Vue {
  flagship_profiling_card_view_model = Vue.observable(new FlagshipProfilingCardViewModel());

  created() {
    this.flagship_profiling_card_view_model.initialize();
  }
}
