import TYPES from '@/types';
import { v4 } from 'uuid';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class FlagshipProfilingCardViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.my-investment-dashboard.flagship-profiling-card.flagship_profiling_card';

  is_loading = false;

  is_showed = false;

  show_flagship_profiling_modal = false;

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: 'flagship',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get background_image() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const bgImage = require('@/assets/icons/fill_3.svg');
    return {
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'right',
      backgroundPositionY: 'bottom',
      backgroundSize: 'contain',
    };
  }

  showFlagshipProfilingModal = async () => {
    await this.obtainInvestmentSuggestion();
    this.show_flagship_profiling_modal = true;
  }

  async initialize() {
    this.is_loading = true;
    await this.obtainInvestorProfile();
    this.is_loading = false;
  }

  obtainInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();

      if (investor_profile) {
        this.is_showed = !investor_profile.is_completed;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  obtainInvestmentSuggestion = async () => {
    try {
      const { id, must_be_shown } = await this.get_investment_suggestion_query.execute();
      if (must_be_shown) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = id;
        await this.createInvestmentSuggestionAction();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investment_suggestion'));
    }
  }

  createInvestmentSuggestionAction = async () => {
    try {
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_investment_suggestion_action'));
    }
  }
}
